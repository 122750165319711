import { Retrier } from "@twilio/operation-retrier";
import { Logger } from "../logger";
import { Configuration } from "../configuration";
import { Transport } from "./transport";
import { MediaCategory } from "../media";

const log = Logger.scope("Network");

class Network {
  private readonly config: Configuration;
  private readonly transport: Transport;

  constructor(config: Configuration, transport: Transport) {
    this.config = config;
    this.transport = transport;
  }

  private backoffConfig() {
    return Object.assign(
      Configuration.backoffConfigDefault,
      this.config.backoffConfigOverride
    );
  }

  private retryWhenThrottled(): boolean {
    return (
      this.config.retryWhenThrottledOverride ??
      Configuration.retryWhenThrottledDefault ??
      false
    );
  }

  private async executeWithRetry(
    request,
    retryWhenThrottled: boolean
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const codesToRetryOn = [502, 503, 504];
      if (retryWhenThrottled) {
        codesToRetryOn.push(429);
      }

      const retrier = new Retrier(this.backoffConfig());
      retrier.on("attempt", async () => {
        try {
          const result = await request();
          retrier.succeeded(result);
        } catch (err) {
          if (codesToRetryOn.indexOf(err.status) > -1) {
            retrier.failed(err);
          } else if (err.message === "Twilsock disconnected") {
            // Ugly hack. We must make a proper exceptions for twilsock
            retrier.failed(err);
          } else {
            // Fatal error
            retrier.removeAllListeners();
            retrier.cancel();
            reject(err);
          }
        }
      });

      retrier.on("succeeded", (result) => {
        resolve(result);
      });
      retrier.on("cancelled", (err) => reject(err));
      retrier.on("failed", (err) => reject(err));

      retrier.start();
    });
  }

  public async get(url: string): Promise<any> {
    const headers = { "X-Twilio-Token": this.config.token };
    log.trace("sending GET request to ", url, " headers ", headers);
    try {
      const response = await this.executeWithRetry(
        () => this.transport.get(url, headers),
        this.retryWhenThrottled()
      );
      log.trace("response", response);
      return response;
    } catch (err) {
      log.debug(`get() error ${err}`);
      throw err;
    }
  }

  public async post(
    url: string,
    category: MediaCategory | null,
    media: string | Buffer | Blob | FormData,
    contentType?: string,
    filename?: string
  ): Promise<any> {
    const headers = {
      "X-Twilio-Token": this.config.token,
    };

    if (
      (typeof FormData === "undefined" || !(media instanceof FormData)) &&
      contentType
    ) {
      Object.assign(headers, {
        "Content-Type": contentType,
      });
    }

    const fullUrl = new URL(url);
    if (category) {
      fullUrl.searchParams.append("Category", category);
    }
    if (filename) {
      fullUrl.searchParams.append("Filename", filename);
    }

    let response;
    log.trace(`sending POST request to ${url} with headers ${headers}`);
    try {
      response = await this.transport.post(fullUrl.href, headers, media);
    } catch (err) {
      // If global["XMLHttpRequest"] is undefined, it means that the code is
      // not being executed in the browser.
      if (global["XMLHttpRequest"] === undefined && media instanceof FormData) {
        throw new TypeError(
          "Posting FormData supported only with browser engine's FormData"
        );
      }
      log.debug(`post() error ${err}`);
      throw err;
    }
    log.trace("response", response);
    return response;
  }
}

export { Network };
